import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import guide from '../views/guide'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home'),
    redirect: "tutorial",
    children: [
      {
        path: '/tutorial',
        name: 'tutorial',
        component: () => import(/* webpackChunkName: "tutorial" */ '../views/tutorial')
      }, {
        path: '/state',
        name: 'state',
        component: () => import(/* webpackChunkName: "state" */ '../views/state')
      }, {
        path: '/invite',
        name: 'invite',
        component: () => import(/* webpackChunkName: "invite" */ '../views/invite')
      }, {
        path: '/activity',
        name: 'activity',
        component: () => import(/* webpackChunkName: "activity" */ '../views/activity')
      }, {
        path: '/me',
        name: 'me',
        component: () => import(/* webpackChunkName: "me" */ '../views/me')
      }
    ]
  },
  {
    path: '/guide',
    name: 'guide',
    component: guide
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/login')
  },
  {
    path: '/loginTelPsd',
    name: 'loginTelPsd',
    component: () => import(/* webpackChunkName: "loginTelPsd" */ '../views/loginTelPsd')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "register" */ '../views/register')
  }, {
    path: '/setUp',
    name: 'setUp',
    component: () => import(/* webpackChunkName: "setUp" */ '../views/setUp')
  }, {
    path: '/withdraw',
    name: 'withdraw',
    component: () => import(/* webpackChunkName: "withdraw" */ '../views/withdraw')
  }, {
    path: '/fundDetails',
    name: 'fundDetails',
    component: () => import(/* webpackChunkName: "fundDetails" */ '../views/fundDetails')
  }, {
    path: '/withdrawAccount',
    name: 'withdrawAccount',
    component: () => import(/* webpackChunkName: "withdrawAccount" */ '../views/withdrawAccount')
  }, {
    path: '/teamactivity',
    name: 'teamactivity',
    component: () => import(/* webpackChunkName: "teamactivity" */ '../views/teamactivity')
  }, {
    path: '/noviceTask',
    name: 'noviceTask',
    component: () => import(/* webpackChunkName: "noviceTask" */ '../views/noviceTask')
  }, {
    path: '/myTeamOne',
    name: 'myTeamOne',
    component: () => import(/* webpackChunkName: "myTeamOne" */ '../views/myTeamOne')
  }, {
    path: '/announlist',
    name: 'announlist',
    component: () => import(/* webpackChunkName: "announlist" */ '../views/announlist')
  }, {
    path: '/announcon',
    name: 'announcon',
    component: () => import(/* webpackChunkName: "announcon" */ '../views/announcon')
  }, {
    path: '/Questionnaire',
    name: 'Questionnaire',
    component: () => import(/* webpackChunkName: "Questionnaire" */ '../views/Questionnaire')
  }, {
    path: '/PlatformIntroduction',
    name: 'PlatformIntroduction',
    component: () => import(/* webpackChunkName: "PlatformIntroduction" */ '../views/PlatformIntroduction')
  }, {
    path: '/log_code',
    name: 'log_code',
    component: () => import(/* webpackChunkName: "log_code" */ '../views/log_code')
  }, {
    path: '/RevenueRecord',
    name: 'RevenueRecord',
    component: () => import(/* webpackChunkName: "RevenueRecord" */ '../views/RevenueRecord')
  }

]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

// 全局前置守卫
router.beforeEach((to, from, next) => {
  // console.log(to.path);
  let token = store.state.token
  if (to.path === '/login' || to.path === '/register' || to.path === '/guide' || to.path === '/loginTelPsd') {
    store.commit('setUserInfo', '');
    store.commit('setToken', '');
    next()
  } else if (to.path === '/language' || to.path === '/tutorial') {
    next()
  } else {
    if (token) {
      // // 菜单高亮
      // if (to.meta.isMenuActive) {
      //   store.commit('changeisMenuActive', to.meta.isMenuActive)
      // }
      next()
    } else {
      next('/guide')
    }
  }

})


export default router
