<template>
  <div id="app">
    <router-view :key="refreshKey" />
    <van-popup :close-on-click-overlay="false" class="van-popup--bottom" v-model="$store.state.isShow"
      position="bottom">
      <van-picker :confirm-button-text="$t('language.Confirm')" :cancel-button-text="$t('language.cancel')"
        @confirm="onConfirm" @cancel="onCancel" show-toolbar value-key="name" :columns="columns"
        :default-index="$store.state.defaultIndex" />
    </van-popup>
    <!-- 右侧旋风按钮 -->
    <div class="btns-right">
      <div class="btn" @click="show = true">
        <img src="@/assets/images/tg.png" alt="">
      </div>
    </div>
    <van-popup v-model="show" closeable round position="bottom" :style="{ height: '40%' }">
      <div class="overall-body-tabbar overall-body">
        <div class="overall-title">Contact us</div>
        <div class="overall-list" @click="openLink">
          <img src="@/assets/images/tg.png" class="logo" alt="">
          Registration login problem
          <img src="@/assets/images/go-back.png" alt="" class="go-back">
        </div>
        <div class="overall-list" @click="openLink">
          <img src="@/assets/images/tg.png" class="logo" alt="">
          withdrawal problem
          <img src="@/assets/images/go-back.png" alt="" class="go-back">
        </div>
        <div class="overall-list" @click="openLink">
          <img src="@/assets/images/tg.png" class="logo" alt="">
          invite friend question
          <img src="@/assets/images/go-back.png" alt="" class="go-back">
        </div>
        <div class="overall-list" @click="openLink">
          <img src="@/assets/images/tg.png" class="logo" alt="">
          Income Calculation Questions
          <img src="@/assets/images/go-back.png" alt="" class="go-back">
        </div>
        <div class="overall-list" @click="openLink">
          <img src="@/assets/images/tg.png" class="logo" alt="">
          Other problems
          <img src="@/assets/images/go-back.png" alt="" class="go-back">
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { getBase } from "@/api/index"

export default {
  data() {
    return {
      show: false,
      refreshKey: true,
      defaultIndex: 0,
      columns: [{
        name: '中文',
        value: 'zh-cn',
      }, {
        name: 'English',
        value: 'en-us',
      }, {
        name: 'Español',
        value: 'es-es',
      }, {
        name: 'Polski',
        value: 'pl-pl',
      }, {
        name: 'čeština',
        value: 'cs-cz',
      }, {
        name: 'dansk',
        value: 'da-dk',
      }, {
        name: 'Ελληνικά',
        value: 'el-gr',
      }, {
        name: 'Limba Română',
        value: 'ro-ro',
      }, {
        name: 'Slovenský',
        value: 'sk-sk',
      }, {
        name: 'Deutsch',
        value: 'de-de',
      }, {
        name: 'Italiano',
        value: 'it-it',
      }, {
        name: '日本語',
        value: 'ja-jp',
      }, {
        name: 'Türkçe',
        value: 'tr-tr',
      }, {
        name: 'عربي',
        value: 'ar-ae',
      }, {
        name: 'Nederlands',
        value: 'nl-nl',
      }, {
        name: 'বাংলা',
        value: 'bn-bn',
      }, {
        name: '한국인',
        value: 'ko-kr',
      }, {
        name: 'Русский',
        value: 'ru-ru',
      }],
      service:""
    }
  },
  created() {
        getBase().then(res => {
            if (res.code == 1) {
                this.service = res.data.service
            }
        })
    },
  methods: {
    // 打开外部链接
    openLink() {
      window.open(this.service, '_blank')
    },
    onConfirm(value, index) {
      // console.log(value, index);
      this.$i18n.locale = value.value
      this.$store.commit('changeLanguage', value.value)
      this.$store.commit('changeDefaultIndex', index)
      this.$store.commit('changeIsShow', false);
      // this.$router.go(0)
      this.refreshKey = !this.refreshKey
    },
    onCancel() {
      this.$store.commit('changeIsShow', false);
    },
  },
  mounted() {
  }
}
</script>
<style lang="scss" scoped>
.overall-body {
  width: 100%;
  padding: 10px;
  background-color: #fff;
  border-radius: 10px 10px 0 0;

  .overall-title {
    width: 355px;
    height: 40px;
    line-height: 40px;
    color: #101010;
    font-size: 16px;
    font-weight: 700;
  }

  .overall-list {
    height: 40px;
    line-height: 40px;
    width: 355px;
    color: #101010;
    font-size: 14px;

    .logo {
      width: 30px;
      height: 30px;
      margin-right: 10px;
      vertical-align: -10px;
    }

    .go-back {
      width: 25px;
      height: 25px;
      float: right;
      top: 10px;
    }
  }
}

.overall-body-tabbar {
  bottom: 47px;
}
</style>
<style lang="scss">
#app {
  max-width: 600px;
  min-height: 100vh;
  margin: 0 auto;
  font-size: 16px;
  font-family: Inter;
  background-color: #f4f4f4;

  .van-popup--bottom {
    max-width: 600px;
    margin: 0 auto;
    right: 0;
  }

  // 悬浮按钮
  .btns-right {
    position: fixed;
    right: 10px;
    bottom: 100px;
    display: flex;
    justify-content: flex-end;
    z-index: 5;

    .btn {
      width: 60px;
      height: 60px;

      img {
        width: 100%;
      }
    }
  }
}
</style>
