<template>
    <div id="guide">
        <div class="hader">Log In</div>
        <img src="@/assets/images/logo.png" alt="">
        <div class="title">Shareasale</div>
        <div class="btn login" @click="$router.push('/login')">Log In</div>
        <div class="btn" @click="$router.push('/register')">Register</div>
        <div class="banben">Version number: 8.0.1</div>
        <van-popup v-model="show">
            <div class="popup-box">
                <div class="popup-title">Exclusive for <br /> new users</div>
                <div class="popup-con">
                    <div class="popup-txt">New user registration limited time reward +100</div>
                    <div class="popup-btn-box">
                        <div class="popup-btn" @click="$router.push('/login')">Go to login</div>
                        <div class="popup-btn popup-huang" @click="$router.push('/register')">Register Now</div>
                    </div>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
export default {
    name: 'guide',
    components: {
    },
    data() {
        return {
            show: false
        }
    },
    created() {
        setTimeout(() => {
            this.show = true
        }, 1000);
    }
}
</script>
<style scoped lang="scss">
#guide {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 11px;
    position: relative;

    .hader {
        width: 100%;
        height: 44px;
        background-color: #009c3b;
        line-height: 44px;
        text-align: center;
        color: #fff;
        font-size: 18px;
        font-weight: 700;
        position: fixed;
        top: 0;
    }

    img {
        width: 100px;
    }

    .title {
        font-size: 30px;
        font-weight: 700;
        color: #009c3b;
    }

    .btn {
        width: 100%;
        height: 49px;
        color: #009c3b;
        background-color: #fff;
        border-radius: 24.5px;
        border: 1px solid #009c3b;
        margin: 25px 0 6px;
        text-align: center;
        line-height: 49px;
        cursor: pointer;

        &.login {
            background-color: #009c3b;
            color: #fff;
            margin-top: 60px;
        }
    }

    .banben {
        position: absolute;
        bottom: 0;
        color: #999;
        font-size: 12px;
    }
}

:deep(.van-popup--center) {
    background-color: rgba(0, 0, 0, 0);
    padding-bottom: 30px;
}

.popup-box {
    width: 320px;
    // height: 300px;
    background-image: url('@/assets/images/popup-bg.png');
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: top center;
    padding-top: 40px;

    .popup-title {
        color: #009B3B;
        font-family: "Microsoft YaHei UI";
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-left: 20px;
        margin-top: 10px;
    }

    .popup-con {
        display: flex;
        padding: 16px;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        border-radius: 20px;
        background: #FFF;

        .popup-txt {
            color: #000;
            text-align: center;
            font-family: "Microsoft YaHei UI";
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        .popup-btn-box {
            display: flex;
            align-items: center;
            gap: 12px;
            align-self: stretch;

            .popup-btn {
                display: flex;
                padding: 10px;
                justify-content: center;
                align-items: center;
                gap: 10px;
                flex: 1 0 0;
                border-radius: 10px;
                background: #009B3B;
                color: #FFF;
                text-align: center;
                font-family: "Microsoft YaHei UI";
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                cursor: pointer;
            }

            .popup-huang {
                background: #FF8800;
            }
        }
    }
}
</style>