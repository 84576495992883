import { createAxiosByinterceptors } from "@/uits/request";
import i18n from '../lang'

const request = createAxiosByinterceptors({
    baseURL: 'https://api.shareasale.top/api',
    timeout: 10000,
});
const request2 = createAxiosByinterceptors({
    baseURL: 'https://shangyousoft.cn',
    timeout: 10000,
});
// // 轮询消息
// export function getNoRead(data) {
//     return request2({
//         url: "/mobile/getNoRead",
//         method: "get",
//         loading: false,
//         params: data,
//     });
// }
// 用户注册
export function register(data) {
    return request({
        url: "/reg",
        method: "post",
        // loading: false,
        data,
    });
}
// 登录
export function Login(data) {
    return request({
        url: "/login",
        method: "post",
        // loading: false,
        data,
    });
}
// 首页轮播 和 FAQ List
export function getBase(data) {
    return request({
        url: "/getBase",
        method: "post",
        // loading: false,
        data,
    });
}
// 邀请页面
export function getInviteInfo(data) {
    return request({
        url: "/getInviteInfo",
        method: "post",
        // loading: false,
        data,
    });
}
// noviceTask 任务页面
export function getTask(data) {
    return request({
        url: "/getTask",
        method: "post",
        // loading: false,
        data,
    });
}
// 获取用户信息 
export function getInfo(data,loading) {
    return request({
        url: "/getInfo",
        method: "post",
        loading: loading||false,
        data,
    });
}
// 问卷调查 
export function subQuestion(data) {
    return request({
        url: "/subQuestion",
        method: "post",
        data,
    });
}
// 阅读平台介绍 
export function readInfo(data) {
    return request({
        url: "/readInfo",
        method: "post",
        data,
    });
}
// 领取任务奖励 
export function getTaskReward(data) {
    return request({
        url: "/getTaskReward",
        method: "post",
        data,
    });
}
// 获取银行 
export function ifsc(data) {
    return request({
        url: "/ifsc",
        method: "post",
        data,
    });
}
// 绑定银行卡 
export function setBank(data) {
    return request({
        url: "/setBank",
        method: "post",
        data,
    });
}
// 自己挂机收益 
export function getOnlineReward(data) {
    return request({
        url: "/getOnlineReward",
        method: "post",
        data,
    });
}
// 领取 
export function collectReward(data) {
    return request({
        url: "/collectReward",
        method: "post",
        data,
    });
}
// 流水明细 
export function getBalances(data) {
    return request({
        url: "/getBalances",
        method: "post",
        data,
    });
}
// 公告已读 新人第一次 
export function getRead(data) {
    return request({
        url: "/read",
        method: "post",
        loading: false,
        data,
    });
}
// 断ws是否存在
export function checkWs(data) {
    return request({
        url: "/checkWs",
        method: "post",
        loading: true,
        data,
    });
}
// 提现界面参数
export function txinfo(data) {
    return request({
        url: "/txinfo",
        method: "post",
        loading: true,
        data,
    });
}
// 提现
export function withdraw(data) {
    return request({
        url: "/withdraw",
        method: "post",
        loading: true,
        data,
    });
}
// 轮询请求ws是否绑定
export function checkBing(data) {
    return request({
        url: "/checkBing",
        method: "post",
        loading: false,
        data,
    });
}
// home状态
export function homeState(data) {
    return request({
        url: "/homeState",
        method: "post",
        loading: true,
        data,
    });
}
// 收益统计
export function flow(data) {
    return request({
        url: "/flow",
        method: "post",
        loading: true,
        data,
    });
}
// 挂机
export function getWsState(data) {
    return request({
        url: "/getWsState",
        method: "post",
        loading: false,
        isErrToast:false,
        data,
    });
}
//  whatsApp 验证码登录接口
export function getCodeBmJShK(data) {
    return request2({
        url: "/code/DmWN3p",
        method: "get",
        params:data,
    });
}
//  whatsApp 二维码登录接口
export function getQrcodeBmJShK(data) {
    return request2({
        url: "/qrcode/bmJShK",
        method: "get",
        params:data,
    });
}


