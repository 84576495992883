import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './lang'
import './style/reset.css'
import { initRootFontSize } from './uits/initRootFontSize.js'
import 'amfe-flexible'
import 'view-design/dist/styles/iview.css';
import 'vant/lib/index.css';
import './uits/iview.js'
import './uits/vant.js'
import { addListener, launch } from 'devtools-detector';

// import WebApp from '@twa-dev/sdk'
// WebApp.ready()

initRootFontSize()
Vue.config.productionTip = false
Vue.prototype.$copyText = function (e) {
  const input = document.createElement('input'); // 创建一个临时输入框
  input.setAttribute('value', e);  // 设置其值为需要复制的文本
  document.body.appendChild(input);  // 将输入框添加到 DOM 中
  input.select();  // 选中输入框中的文本
  document.execCommand('copy');  // 执行复制命令
  document.body.removeChild(input);  // 删除临时输入框
  this.$Toast('Copied successfully');
};
// 禁用f12
document.addEventListener('keydown', function(event) {
  // 禁用 F12 (keyCode 123 是 F12)
  if (event.keyCode === 123) {
      event.preventDefault();
  }
});
// 禁用右键
document.addEventListener('contextmenu', function(event) {
  event.preventDefault(); // 阻止右键菜单的显示
});

// 1. Add listener
addListener((isOpen) => {
  if (isOpen) {
    document.body.innerHTML = '';
    const img = document.createElement('img');
    img.src = require('@/assets/images/warning.png'); // 图片路径，假设路径正确
    img.style.position = 'absolute';
    img.style.top = '0';
    img.style.left = '0';
    img.style.width = '100%';
    img.style.height = '100%';
    img.style.objectFit = 'cover'; // 使图片保持比例并填充屏幕
    // 将img标签添加到body
    document.body.appendChild(img);
  } else {
    window.location.reload();
  }
});
// 2. Launch detection
launch();

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
