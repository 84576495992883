import Vue from 'vue';
import {ImagePreview ,RadioGroup, Radio,Field , Button,NoticeBar,Collapse, CollapseItem,NavBar,Dialog,List,Cell,Icon,ActionSheet,Toast,Swipe,SwipeItem,Lazyload,Popup,Picker,Badge,Popover } from 'vant';

Vue.use(Button);
Vue.use(NoticeBar);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(NavBar);
Vue.use(Dialog);
Vue.use(List);
Vue.use(Cell);
Vue.use(ActionSheet);
Vue.use(Icon);
Vue.use(Toast);
// Vue.use(Form);
Vue.use(Field);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Lazyload);
Vue.use(Popup);
Vue.use(Picker);
Vue.use(Badge);
Vue.use(Popover);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(ImagePreview);


Vue.prototype.$Toast = Toast
Vue.prototype.$Dialog = Dialog
Vue.prototype.$ImagePreview = ImagePreview


// 注册时可以配置额外的选项
Vue.use(Lazyload, {
  lazyComponent: true,
});
